import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent {

  items: Observable<any[]>;
  
  constructor(firestore: AngularFirestore) {
    this.items = firestore.collection('portfolio', ref => ref.orderBy('sort','desc')).valueChanges();
  }

}
