<div id="navbar">
    <div class="container">
        <div class="row">
            <div class="grid-left">
                <a routerLink="/" class="title">Ibnu Imam</a>
            </div>
            <div class="grid-right">
                <ul>
                    <li><a routerLink="/portfolio"><span class="icon-portfolio"></span></a></li>
                    <li><a routerLink="/resume"><span class="icon-resume"></span></a></li>
                    <li><a routerLink="/gallery"><span class="icon-photo"></span></a></li>
                    <li><a routerLink="/contact"><span class="icon-email"></span></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div id="footer-navbar">
    <div class="container">
        <ul>
            <li><a routerLink="/portfolio"><span class="icon-portfolio"></span></a></li>
            <li><a routerLink="/resume"><span class="icon-resume"></span></a></li>
            <li><a routerLink="/gallery"><span class="icon-photo"></span></a></li>
            <li><a routerLink="/contact"><span class="icon-email"></span></a></li>
        </ul>
    </div>
</div>