import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent {

  educations: Observable<any[]>;
  experience: Observable<any[]>;
  skills: Observable<any[]>;
  cv: Observable<any[]>
  cvLink: string;

  constructor(firestore: AngularFirestore) {
    this.educations = firestore.collection('education', ref => ref.orderBy('sort', 'desc')).valueChanges();
    this.experience = firestore.collection('experience', ref => ref.orderBy('sort', 'desc')).valueChanges();
    this.skills = firestore.collection('skills', ref => ref.orderBy('sort', 'desc')).valueChanges();
    this.cv = firestore.collection('cv').valueChanges();
    this.cv.subscribe(res => {
      this.cvLink = res.length > 0 ? res[0].link : '';
    });
  }

}
