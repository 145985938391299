<app-navbar></app-navbar>
<div id="contact-page">
    <div class="container">
        <div id="section-subtitle">
            <h2>Contact</h2>
            <h3>"A single rose can be my garden.. a single friend, my world"</h3>
        </div>
        <div id="section-content">
            <div class="list">
                <div class="label"><span class="icon-whatsapp"></span> Phone</div>
                <div class="value">+62 852 2233 8644</div>
            </div>
            <div class="list">
                <div class="label"><span class="icon-email"></span> Email</div>
                <div class="value">me@ibnuimam.com</div>
            </div>
            <div class="list">
                <div class="label"><span class="icon-web"></span> Web</div>
                <div class="value">www.ibnuimam.com</div>
            </div>
            <div class="list">
                <div class="label"><span class="icon-link"></span> Blog</div>
                <div class="value">blog.ibnuimam.com</div>
            </div>
        </div>
        <div id="section-quote">
            <ul>
                <li><a href="https://www.linkedin.com/in/ibnuimam/"><span class="icon-linkedin"></span></a></li>
                <li><a href="https://www.facebook.com/iben.sg"><span class="icon-facebook"></span></a></li>
                <li><a href="https://twitter.com/ibengelo"><span class="icon-twitter"></span></a></li>
                <li><a href="https://www.instagram.com/ibengelo/"><span class="icon-instagram"></span></a></li>
            </ul>
            <a class="btn" target="_blank" href="mailto:me@ibnuimam.com"><span class="icon-email"></span> Mail me
                now</a>
            <a class="btn" target="_blank"
                href="https://wa.me/6285222338644?text=Saya%20tertarik%20kerjasama%20setelah%20lihat%20profil%20kamu"><span
                    class="icon-whatsapp"></span> Call me now</a>
        </div>
        <div id="section-footer">
            <app-footer></app-footer>
        </div>
    </div>
</div>