<app-navbar></app-navbar>
<div id="resume-page">
    <div class="container">
        <div id="section-subtitle">
            <h2>Resume</h2>
            <h3>"Education is not preparation for life, education is life itself"</h3>
        </div>
        <div id="section-content">
            <div class="grid-left">
                <h3><span class="icon-education"></span> Education</h3>
                <div *ngFor="let education of educations | async" class="list">
                    <app-list [title]="education.title" [subtitle]="education.school" [location]="education.location"
                        [start]="education.start" [end]="education.end"></app-list>
                </div>
            </div>
            <div class="grid-right">
                <h3><span class="icon-work-experience"></span> Work Experience</h3>
                <div *ngFor="let exp of experience | async" class="list">
                    <app-list [title]="exp.title" [subtitle]="exp.office" [location]="exp.location" [start]="exp.start"
                        [end]="exp.end"></app-list>
                </div>
            </div>
            <div class="grid-center">
                <h3><span class="icon-code"></span> Technical Skills</h3>
                <div *ngFor="let skill of skills | async" class="list">
                    <app-progress [title]="skill.title" [percent]="skill.percent"></app-progress>
                </div>
            </div>
        </div>
        <div *ngIf="cvLink !== ''" id="section-quote">
            <p>"Need more detail about me"</p>
            <a target="_blank" [href]="cvLink"><span class="icon-link"></span> Download as PDF</a>
        </div>
        <div id="section-footer">
            <app-footer></app-footer>
        </div>
    </div>
</div>