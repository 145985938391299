<div id="home-page">
    <div class="container">
        <div id="section-profile">
            <img src="/assets/images/ibnuimam.jpg" class="img-profile" />
            <h1>Ibnu Imam</h1>
            <h2>I am Web Engineer</h2>
        </div>

        <div id="section-menu">
            <app-footer></app-footer>
        </div>
    </div>
</div>