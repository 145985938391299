<app-navbar></app-navbar>
<div id="portfolio-page">
    <div class="container">
        <div id="section-subtitle">
            <h2>Portfolio</h2>
            <h3>"Creativity comes in walking time"</h3>
        </div>
        <div id="section-content">
            <div *ngFor="let item of items | async" class="grid">
                <app-card 
                [title]="item.title" 
                [type]="item.type" 
                [description]="item.description"
                [imageUrl]="item.image"
                ></app-card>
            </div>
            <div *ngFor="let item of items | async" class="breaker"></div>
        </div>
        <div id="section-quote">
            <p>"Contact me to discuss your next project"</p>
            <a target="_blank"
                href="https://wa.me/6285222338644?text=Saya%20tertarik%20kerjasama%20setelah%20lihat%20profil%20kamu"><span
                    class="icon-whatsapp"></span> Call me now</a>
        </div>
        <div id="section-footer">
            <app-footer></app-footer>
        </div>
    </div>
</div>