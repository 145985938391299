<app-navbar></app-navbar>
<div id="gallery-page">
    <div class="container">
        <div id="section-subtitle">
            <h2>Gallery</h2>
            <h3>"Every line has a meaning and every picture has a story"</h3>
        </div>
        <div id="section-content">
            <div *ngFor="let item of items | async" class="grid">
                <img [src]="item.image" />
            </div>
            <div *ngFor="let item of items | async" class="breaker"></div>
        </div>
        <div id="section-footer">
            <app-footer></app-footer>
        </div>
    </div>
</div>