<div class="card">
    <div class="card-title">
        <h2>{{title}}</h2>
        <p>{{type}}</p>
    </div>
    <div class="card-image">
        <img [src]="imageUrl" [alt]="title" />
    </div>
    <div class="card-description">
        <p class="question">What I do here?</p>
        <p>{{description}}</p>
    </div>
</div>